import dynamic from 'next/dynamic'
import { Drawer, Toolbar, IconButton } from '@mui/material'
import { dir } from 'i18next'
import { MenuTwoTone as MenuTwoToneIcon, ChevronRightTwoTone as ChevronRightTwoToneIcon, ChevronLeftTwoTone as ChevronLeftTwoToneIcon } from '@mui/icons-material'

// Types
import type { DrawerProps } from '@/types/components/molecules/drawer'

// Configurations
import ThemeStyleConfig from '@/config/theme-style'

// Components
const LogoAtom = dynamic(() => import('@/components/atoms/shapes/logo'))

const PermanentDrawerMolecule = (props: DrawerProps) => {
	// Props
	const { open, setOpen, children } = props

	// Variables
	const langDir = dir()

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			open={open}
			onClose={() => setOpen(false)}
			transitionDuration={0}
			sx={{
				display: { xs: 'none', md: 'block' },
				'& .MuiDrawer-paper': {
					width: open ? ThemeStyleConfig.drawerWidth : 'calc(var(--mui-spacing) * 8)',
					px: 1
				},
				...(!open && { '& .MuiToolbar-root': { px: 0.5 } })
			}}
		>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={() => setOpen(!open)}
					sx={{
						...(open ? { mr: 2, ml: -2.5 } : { ml: 0 }),
						borderRadius: 1,
						'& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius: 1 }
					}}
				>
					{open ? langDir === 'rtl' ? <ChevronRightTwoToneIcon /> : <ChevronLeftTwoToneIcon /> : <MenuTwoToneIcon />}
				</IconButton>

				{open && <LogoAtom width={25} height={25} />}
			</Toolbar>

			{children}
		</Drawer>
	)
}

export default PermanentDrawerMolecule
